<template>
  <PageListLayout
    icon="mdi-home"
    noFilter
    title="Danh sách công ty"
    subTitle="Danh sách công ty"
    useAdd
    titleAdd="Tạo mới công ty"
    @add="createItem"
  >
    <!-- <template slot="filter">
      <v-text-field
        append-icon="mdi-magnify"
        v-model="search"
        label="Tìm kiếm"
        clearable
        single-line
        outlined
        dense
        hide-details
      ></v-text-field>
    </template> -->
    <CustomTable
      :headers="headers"
      :items="tableData"
      :page.sync="page"
      :items-per-page="itemsPerPage"
      hide-default-footer
      :loading="loading"
      loading-text="Đang tải dữ liệu ..."
      :pageCount="pageCount"
      @change-page="changePage"
      classPaging="pt-2"
    >
      <template v-slot:[`item.action`]="{ item }">
        <v-menu>
          <template v-slot:activator="{ on, attrs }">
            <v-btn icon v-bind="attrs" v-on="on">
              <v-icon color="textColor">mdi-dots-vertical</v-icon>
            </v-btn>
          </template>
          <v-list>
            <v-list-item @click="editItem(item)">
              <v-list-item-title>
                <v-icon class="mr-2"> mdi-pencil </v-icon>
                Cập nhật</v-list-item-title
              >
            </v-list-item>
            <v-list-item @click="deleteItem(item)">
              <v-list-item-title>
                <v-icon class="mr-2"> mdi-delete </v-icon>
                Xóa Công ty</v-list-item-title
              >
            </v-list-item>
          </v-list>
        </v-menu>
      </template>
    </CustomTable>
    <create-edit
      :danhSachNganHang="danhSachNganHang"
      :customArea="customArea"
      ref="form"
      @on-done="getAllList"
    ></create-edit>
  </PageListLayout>
</template>
<script>
import { getCompanyManagers, deleteCompanyManagers } from "@/api/congty";
import CreateEdit from "./create-edit";
import { debounce } from "lodash";
import { TYPE } from "@/constants/congtynhapkhau";
import { index as getDanhSachNganHang } from "@/api/nganhang.api";
import { getAllCustomsArea } from "@/api/khuvuchaiquan";

export default {
  components: { CreateEdit },
  data() {
    return {
      page: 1,
      pageCount: 1,
      itemsPerPage: 10,
      tableData: [],
      btnLoading: false,
      menu: {},
      loading: false,
      search: "",
      danhSachNganHang: [],
      customArea: [],
      headers: [
        // { text: "STT", width: "100", sortable: false },
        // { text: "Icon", value: "icon", sortable: false },
        {
          text: "Mã khách hàng",
          align: "start",
          sortable: false,
          value: "code",
        },
        { text: "Mã công ty", value: "name" },
        { text: "SĐT", value: "phone" },
        { text: "Mã số thuế", value: "tax" },
        { text: "Email", value: "email" },
        { text: "Ghi chú", value: "description" },
        {
          text: "Hành động",
          value: "action",
          sortable: false,
          align: "center",
          width: "150",
        },
      ],
    };
  },
  computed: {},
  created() {
    this.getAllList();
    this.fetchCustomArea();
    this.fetchDanhSachNganHang();
  },
  watch: {
    search: debounce(async function(val) {
      this.loading = true;
      let data = await getCompanyManagers({
        page: this.page,
        perPage: this.itemsPerPage,
        search: val,
      });
      this.loading = false;
      this.tableData = data.data;
      this.pageCount = data.last_page;
    }, 300),
  },
  methods: {
    async fetchCustomArea() {
      const res = await getAllCustomsArea();
      this.customArea = res;
    },
    async getAllList() {
      this.loading = true;
      let data = await getCompanyManagers({
        type: TYPE,
        page: this.page,
        perPage: this.itemsPerPage,
      });
      this.loading = false;
      this.tableData = data.data;
      this.pageCount = data.last_page;
    },
    changePage(val) {
      this.page = val;
      this.getAreasList();
    },
    editItem(item) {
      this.$refs.form.showFormEdit(item);
    },
    async deleteItem(item) {
      this.$confirmBox.show({
        title: "Xóa Khách Hàng",
        width: 500,
        body:
          "Bạn có chắc chắn muốn công ty " +
          "<strong>" +
          item.name +
          " ?" +
          "</strong>",
        action: () => deleteCompanyManagers({ id: item.id }),
        onDone: this.getAllList,
      });
    },
    createItem() {
      this.$refs.form.showFormAdd();
    },
    async fetchDanhSachNganHang() {
      const res = await getDanhSachNganHang({
        pagination: false,
      });
      this.danhSachNganHang = res;
    },
  },
};
</script>
