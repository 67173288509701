<template>
  <v-dialog v-model="show" persistent width="800">
    <v-card>
      <v-card-title class="headline">{{
        edit ? "CẬP NHẬT CÔNG TY" : "THÊM CÔNG TY"
      }}</v-card-title>
      <br />
      <v-card-text>
        <v-form ref="form">
          <v-row>
            <v-col cols="6">
              <div class="label-form">Mã công ty</div>
              <v-text-field
                v-model="form.code"
                placeholder="Mã công ty"
                outlined
                dense
                prepend-inner-icon="mdi-code-tags"
                :rules="codeRules"
                :disabled="edit ? true : false"
              ></v-text-field>
            </v-col>
            <v-col cols="6">
              <div class="label-form">Tên công ty</div>
              <v-text-field
                v-model="form.name"
                placeholder="Tên công ty"
                outlined
                dense
                :rules="nameRules"
              ></v-text-field>
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="6">
              <div class="label-form">Mã số thuế</div>
              <v-text-field
                v-model="form.tax"
                placeholder="Mã số thuế"
                outlined
                dense
                prepend-inner-icon="mdi-email"
                :rules="taxRules"
              ></v-text-field>
            </v-col>
            <v-col cols="6">
              <div class="label-form">Khu vực hải quan</div>
              <v-combobox
                v-model="form.khu_vuc"
                :items="customArea"
                placeholder="Khu vực hải quan"
                :rules="required"
                item-text="name"
                item-value="id"
                dense
                outlined
                :return-object="false"
              ></v-combobox>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="6">
              <div class="label-form">Người đại diện</div>
              <v-text-field
                v-model="form.agent_name"
                placeholder="Người đại diện"
                outlined
                dense
                prepend-inner-icon="mdi-account-network"
              ></v-text-field>
            </v-col>
            <v-col cols="6">
              <div class="label-form">Chức vụ người đại diện</div>
              <v-text-field
                v-model="form.agent_position"
                placeholder="Chức vụ người đại diện"
                outlined
                dense
                prepend-inner-icon="mdi-menu-down"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="6">
              <div class="label-form">SĐT</div>
              <v-text-field
                v-model="form.phone"
                placeholder="Số điện thoại công ty"
                outlined
                dense
                prepend-inner-icon="mdi-rename-box"
                :rules="phoneRules"
              ></v-text-field>
            </v-col>
            <v-col cols="6">
              <div class="label-form">Email</div>
              <v-text-field
                v-model="form.email"
                placeholder="Email"
                outlined
                dense
                prepend-inner-icon="mdi-cellphone"
                :rules="emailRules"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12">
              <div class="label-form">Địa chỉ</div>
              <v-text-field
                v-model="form.address"
                placeholder="Địa chỉ"
                outlined
                dense
                prepend-inner-icon="mdi-map-marker-outline"
              ></v-text-field>
            </v-col>
            <v-col cols="12">
              <div v-if="!edit" class="d-flex flex-column" style="height: 100%">
                <div class="label-form flex-grow-0">Thêm mới Logo</div>
                <ImageUpdate width="100%" height="200px" v-model="form.file" />
              </div>
              <div v-else class="d-flex flex-column" style="height: 100%">
                <div class="label-form flex-grow-0">Cập nhật Logo</div>
                <ImageUpdate
                  width="100%"
                  height="200px"
                  :value="form.file.image.link"
                  v-model="form.file"
                />
              </div>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="4">
              <div class="label-form">Tên ngân hàng</div>
              <v-autocomplete
                prepend-inner-icon="mdi-map-marker"
                v-model="form.bank_id"
                :items="danhSachNganHang"
                outlined
                dense
                :item-text="(item) => `${item.shortName} - ${item.vn_name}`"
                item-value="id"
                placeholder="Tên ngân hàng"
                clearable
              >
              </v-autocomplete>
            </v-col>
            <v-col cols="4">
              <div class="label-form">Số tài khoản</div>
              <v-text-field
                v-model="form.account_number"
                placeholder="Số tài khoản"
                outlined
                dense
                prepend-inner-icon="mdi-map-marker-outline"
              ></v-text-field>
            </v-col>
            <v-col cols="4">
              <div class="label-form">Chi nhánh ngân hàng</div>
              <v-text-field
                v-model="form.branch"
                placeholder="Chi nhánh ngân hàng"
                outlined
                dense
                prepend-inner-icon="mdi-map-marker-outline"
              ></v-text-field>
            </v-col>
            <v-col cols="12"> </v-col>
          </v-row>
          <!-- <v-row>
            <v-col cols="12">
              <div class="label-form">Ghi chú</div>
              <v-textarea
                v-model="form.description"
                placeholder="Ghi chú"
                outlined
                dense
                prepend-inner-icon="mdi-note"
                no-resize
              ></v-textarea>
            </v-col>
          </v-row> -->
        </v-form>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn text @click="show = false" class="mr-4">Đóng</v-btn>
        <v-btn
          :loading="btnLoading"
          color="primary"
          @click="addItem"
          v-if="!edit"
        >
          <v-icon left>mdi-plus</v-icon>
          Thêm mới
        </v-btn>
        <v-btn :loading="btnLoading" color="primary" @click="editItem" v-else>
          <v-icon left>mdi-pencil</v-icon>
          Cập nhật
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import {
  addCompanyManagers,
  editCompanyManagers,
  UpdateUploadImage,
} from "@/api/congty";
import { TYPE } from "@/constants/congtynhapkhau";
export default {
  props: {
    danhSachNganHang: { type: Array, default: () => [] },
    customArea: { type: Array, default: () => [] },
  },
  data: () => ({
    show: false,
    edit: false,
    btnLoading: false,
    pathURL: "",
    form: {
      code: "",
      name: "",
      phone: "",
      tax: "",
      email: "",
      description: "",
      file: {},
    },
    required: [(v) => !!v || "Không thể bỏ trống"],
    nameRules: [(v) => !!v || "Tên khách hàng không thể bỏ trống"],
    codeRules: [(v) => !!v || "Tên khách hàng không thể bỏ trống"],
    phoneRules: [(v) => !!v || "Tên khách hàng không thể bỏ trống"],
    taxRules: [(v) => !!v || "Tên khách hàng không thể bỏ trống"],
    emailRules: [
      (v) => !!v || "E-Mail không thể bỏ trống",
      (v) => /.+@.+\..+/.test(v) || "E-mail không hợp lệ",
    ],
  }),
  computed: {},
  methods: {
    showFormAdd() {
      this.show = true;
      this.edit = false;
      this.form = {
        code: "",
        name: "",
        phone: "",
        tax: "",
        email: "",
        description: "",
        file: {},
      };
    },
    async showFormEdit(data) {
      this.edit = true;
      this.show = true;
      this.form = { ...data };
      Object.assign(this.form, {
        file: {
          image: {
            link: data.image,
          },
        },
      });
    },

    async addItem() {
      this.$refs.form.validate();
      if (this.$refs.form.validate()) {
        this.btnLoading = true;
        try {
          let data = { ...this.form };
          data.type = TYPE;
          let formData = new FormData();
          for (let key in data) {
            formData.append(key, data[key]);
          }

          formData.append("file", data.file.file);
          await addCompanyManagers(formData);
          this.show = false;
          this.btnLoading = false;
          this.$emit("on-done");
          this.$toast.info("Thêm công ty thành công", {
            position: "top-center",
            timeout: 2000,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            draggablePercent: 0.6,
            showCloseButtonOnHover: false,
            hideProgressBar: true,
            closeButton: "button",
            icon: true,
          });
        } catch (error) {
          this.btnLoading = false;
          this.show = false;
        }
      }
    },
    async editItem() {
      this.$refs.form.validate();
      if (this.$refs.form.validate()) {
        this.btnLoading = true;
        try {
          let data = { ...this.form };
          data.type = TYPE;
          let file = data.file;

          if (Object.prototype.hasOwnProperty.call(file, "url_image")) {
            let formData = new FormData();
            formData.append("file", data.file.file);
            formData.append("id", data.id);
            const response_path = await UpdateUploadImage(formData);
            data.image = response_path.data;
          }
          await editCompanyManagers(data);
          this.show = false;
          this.btnLoading = false;
          this.$emit("on-done");
          this.$toast.info("Cập nhật thành công", {
            position: "top-center",
            timeout: 2000,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            draggablePercent: 0.6,
            showCloseButtonOnHover: false,
            hideProgressBar: true,
            closeButton: "button",
            icon: true,
          });
        } catch (error) {
          this.btnLoading = false;
          this.show = false;
        }
      }
    },
  },
};
</script>
