import request from "../utils/request";

export function getCustomsArea(params) {
    return request({
        url: "customsarea",
        method: "get",
        params,
    });
}

export function getAllCustomsArea() {
    return request({
        url: "allcustomsarea",
        method: "get",
    });
}

export function addCustomsArea(data) {
    return request({
        url: "customsarea",
        method: "post",
        data,
    });
}

export function editCustomsArea(data) {
    return request({
        url: "customsarea",
        method: "put",
        data,
    });
}

export function deleteCustomsArea(data) {
    return request({
        url: "/customsarea",
        method: "delete",
        data
    });
}

